import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CosteventService } from '@app/pages/costevents/costevent.service';
import { DashboardService } from '@app/pages/dashboard/dashboard.service';
import { ProgramList, ProgramListResponse, virtualProgramDetailResponse, VirtualProgramList, VirtualProgramResponse, virtualProjects } from '@app/pages/master/master.model';
import { MasterService } from '@app/pages/master/master.service';
import { ProjectlistService } from '@app/pages/projects/projectlist/projectlist.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-program-project-filter',
  templateUrl: './program-project-filter.component.html',
  styleUrls: ['./program-project-filter.component.css']
})
export class ProgramProjectFilterComponent implements OnInit {
  @ViewChild('programSelection') programSelection;
  @ViewChild('projectSelection') projectSelection;
  filterForm: FormGroup;
  subscriptions: Subscription[] = [];
  programDropdownSettings = {};
  projectDropdownSettings = {};
  projectlist = [];
  programList = [];
  tempProjectlist = [];
  tempAllProjectlist = [];
  selectedAllProgram = false;
  selectedAllProject = false;
  selectedAllProgramsAndProjects;
  closeoutKPIData;
  virtualProgramList: VirtualProgramList[] = [];
  virtualProjects: virtualProjects[] = [];
  alreadySelectedVirtualProjects = [];
  programVisible: boolean = true;
  isVPvisible: boolean = false;
  programSelect = [];
  activeTab;
  selectedProjectIds = [];

  constructor(private formBuilder: FormBuilder, private _masterService: MasterService,
    private _projectList: ProjectlistService,
    private dashboardService: DashboardService,
    private _costEventService: CosteventService
  ) {
    this.filterForm = this.formBuilder.group({
      programNames: ['', Validators.required],
      projectNames: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.initControls ();
    this.getProgramList();
    localStorage.removeItem('selectedProjectIds');
  }

  private initControls () {
    this.programDropdownSettings = {
      singleSelection: false,
      idField: 'programID',
      textField: 'programName',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };
    this.projectDropdownSettings = {
      singleSelection: false,
      idField: 'projectID',
      textField: 'projectName',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  getProgramList() {
    this.subscriptions.push(
      this._masterService.getProgramList().subscribe((list: ProgramListResponse) => {
        const result = list.programs;
        this.programList = [];
        for (let i = 0; i < result.length; i++) {
          const program = new ProgramList();
          program.programNumber = result[i].programNumber;
          program.programID = result[i].programID;
          program.programName = result[i].programName;
          program.programNumber = result[i].programNumber;
          this.programList.push(program);
        }
        this.getAllProjectList();
      }));
  }

  getProjectList(Id) {
    this.subscriptions.push(
      this._projectList.getProjectList(Id).subscribe(list => {
        if (this.projectlist.length === 0) {
          this.projectlist = list.project;
          this.tempProjectlist.push(...list.project);
        } else {
          this.tempProjectlist.push(...list.project);
          this.projectlist = this.tempProjectlist;
        }
      }));
  }

  async getAllProjectList() {
    this.subscriptions.push(
      this.dashboardService.getActiveAllProject().subscribe(async res => {
        this.tempAllProjectlist = await res.activeProjectList.table.map(key => ({ projectID: key['projectID'], projectName: key['projectName'], programId: key['programId'] }));

        if (this.programSelection.isAllItemsSelected()) {
          this.selectedAllProgram = true;
        }

        if (this.selectedAllProgram) {
          this.tempProjectlist = this.tempAllProjectlist.slice();
          this.projectlist = this.tempProjectlist.slice();
        } else {
          const pros = this.programSelection.selectedItems;

          if (pros !== undefined) {
            pros.forEach(element => {
              this.tempProjectlist = this.projectlist.concat(this.tempAllProjectlist.filter(x => x.programId === element.id));
            });
            this.projectlist = this.tempProjectlist.slice();
          }
        }
        if (this.selectedAllProgramsAndProjects == "true") {
          this.selectedAllProgram = true;
          this.selectedAllProject = true;
          this.filterForm.controls['programNames'].setValue(this.programList);
          this.filterForm.controls['projectNames'].setValue(this.tempAllProjectlist);
        } else if (this.closeoutKPIData) {
          this.filterForm.controls['programNames'].setValue(this.closeoutKPIData.programNames);
          this.filterForm.controls['projectNames'].setValue(this.closeoutKPIData.projectNames);
        }
      }));
  }

  onSelectAllProgram() {
    this.selectedAllProgram = true;
    this.projectlist = [];
    this.tempProjectlist = [];
    this.tempProjectlist = this.tempAllProjectlist.slice();
    this.projectlist = this.tempProjectlist.slice();
  }

  onSelectProgram(event) {
    this.projectlist = [];
    this.tempProjectlist = this.tempProjectlist.concat(this.tempAllProjectlist.filter(x => x.programId === event.programID));
    this.projectlist = this.tempProjectlist.slice();
  }

  onDeSelectProgram(event) {
    this.selectedAllProgram = false;
    this.selectedAllProject = false;
    this.projectlist = [];
    this.tempProjectlist = this.tempProjectlist.filter(x => x.programId !== event.programID);
    this.projectlist = this.tempProjectlist.slice();
    const items = this.projectSelection.selectedItems;
    this.projectSelection.selectedItems = [];
    const selected = [];
    items.forEach(x => {
      const find = this.projectlist.find(y => y.projectID === x.id);

      if (find !== undefined) {
        this.projectSelection.selectedItems.push(x);
        selected.push(find);
      }
    });
    this.projectSelection.selectedItems = this.projectSelection.selectedItems.slice();
    this.filterForm.controls['projectNames'].setValue(selected);
  }

  onDeSelectAllProgram() {
    this.selectedAllProgram = false;
    this.selectedAllProject = false;
    this.projectlist = [];
    this.tempProjectlist = [];
    this.projectSelection.selectedItems = [];
    this.filterForm.controls['projectNames'].setValue('');
  }

  onDeSelectProject(event) {
    const projectId = event.projectID; // Replace with the actual property
    const index = this.selectedProjectIds.indexOf(projectId);
    if (index > -1) {
      this.selectedProjectIds.splice(index, 1);
    }
    this._costEventService.emitProjectId(this.selectedProjectIds);
  }

  onSelectAllProject() {
    this.selectedAllProject = true;
  }

  onDeSelectAllProject() {
    this.selectedAllProject = false;
  }

  getVirtualProgramList() {
    this.subscriptions.push(
      this._masterService.getAllActiveVirtualProgram().subscribe((list: VirtualProgramResponse) => {
        const result = list.virtualProgramList;
        this.virtualProgramList = [];
        for (let i = 0; i < result.length; i++) {
          const virtualprogram = new VirtualProgramList();
          virtualprogram.programID = result[i].programId;
          virtualprogram.programName = result[i].programName;
          virtualprogram.programNumber = result[i].programNumber;
          virtualprogram.totalVirtualProjects = result[i].totalVirtualProjects;
          virtualprogram.isActive = result[i].isActive;
          if (result[i].isActive) {
            virtualprogram.status = 1;
          } else if (!result[i].isActive) {
            virtualprogram.status = 0;
          }
          this.virtualProgramList.push(virtualprogram);
        }
      }));
  };

  getVirtualProgramProjects() {
    this.subscriptions.push(
      this._masterService.getVirtualProjects().subscribe((list: virtualProgramDetailResponse) => {
        let result = list.virtualProgramDetails;
        for (const iterator of result) {
          let VPDetail: any = new virtualProjects();
          VPDetail.programID = iterator.programID;
          VPDetail.projectNumber = iterator.projectNumber;
          VPDetail.projectName = iterator.projectName;
          VPDetail.projectID = iterator.projectID;
          this.virtualProjects.push(VPDetail);
        }
      }));
  }

  get closeoutItemFormControl() {
    return this.filterForm.controls;
  }

  virtualPrograms(event: MatCheckboxChange): void {
    this.isVPvisible = true
    this.programVisible = false
    this.getVirtualProgramList();
    this.getVirtualProgramProjects();
    this.onDeSelectProgram(event);
    this.programSelect = [];
    this.filterForm.controls['projectNames'].setValue('');
  }

  onSelectVirtualProgram(event) {
    this.alreadySelectedVirtualProjects = this.alreadySelectedVirtualProjects.concat(
      this.virtualProjects.filter(p => p.programID === event.programID && !p.isSelected)
    );
    this.alreadySelectedVirtualProjects.forEach(p => p.isSelected = true);

    this.alreadySelectedVirtualProjects = this.alreadySelectedVirtualProjects.reduce((unique, project) => {
      return unique.some(p => p.projectName === project.projectName) ? unique : [...unique, project];
    }, []);
  }

  onDeSelectAllVirtualProgram() {
    this.filterForm.controls['projectNames'].setValue('');
    this.alreadySelectedVirtualProjects = [];
    this.selectedAllProgram = false;
    this.selectedAllProject = false;
  }

  onSelectAllVirtualProgram() {
    this.selectedAllProgram = true;
    this.alreadySelectedVirtualProjects = [];
    this.alreadySelectedVirtualProjects = this.virtualProjects
      .filter((value, index, self) => {
        return (
          self.findIndex((item) => item.projectName === value.projectName) ===
          index
        );
      });
  }

  virtualProgramUnchecked(): void {
    this.isVPvisible = false
    this.programVisible = true
    this.getProgramList();
    this.programSelect = [];
    this.filterForm.controls['projectNames'].setValue('');
    localStorage.removeItem('isCloseoutKpi');
  }

  onDeSelectVirtualProgram(event) {
    this.filterForm.controls['projectNames'].setValue('');
    this.alreadySelectedVirtualProjects = this.alreadySelectedVirtualProjects.filter(p => p.programID !== event.programID);
    this.virtualProjects.forEach(p => {
      if (p.programID === event.programID) {
        p.isSelected = false;
      }
    });
  }

  onSubmit(event) {
    const projectId = event.projectID; // Replace with the actual property
    this.selectedProjectIds.push(projectId);
    localStorage.setItem('selectedProjectIds', JSON.stringify(this.selectedProjectIds));
    this._costEventService.emitProjectId(this.selectedProjectIds);
  }
}