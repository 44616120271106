import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minusSignToParens'
})
export class MinusSignToParens implements PipeTransform {

  transform(value: any): any {
    if(value){
    return value.charAt(0) == '-' ?
      '(' + value.substring(1, value.length) + ')' :
      value;
    }
  }

}
