import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsService } from '../assets.service';
//import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Location } from '@angular/common';
import { HelperService } from '@app/pages/projects/punchlilst/helper.service';
import { SendemailComponent } from '@app/pages/GeneralItems/sendemail/sendemail.component';
import { FILE_NAMES } from '@app/pages/GeneralItems/sendemail/fileNames.helper';
import { MatDialog } from '@angular/material/dialog';
require('jspdf-autotable');

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.css'],
  providers: [AssetsService]
})

export class AssetDetailComponent implements OnInit {
  // elementType = NgxQrcodeElementTypes.URL;
  // correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  assetId = '';
  assetDetail = [];
  viewShare = true;
  warrantyArray = [
    {
      warrantyEndDate: "",
      warrantyStartDate: "",
      cost: 0,
      websiteLink: "",
    },
  ];

  constructor(private route: ActivatedRoute, private _assetsService: AssetsService, private router: Router,
    private _location: Location,
    private _helperService: HelperService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getProjectAssetDetail();
  }

  getProjectAssetDetail() {
    this.assetId = this.route.snapshot.paramMap.get('assetId');
    if (this.assetId) {
      this._assetsService.getProjectAssetDetail(this.assetId).subscribe(res => {
        this.assetDetail = res.assetDetail;
      })
    }
  }

  editAssetInfo() {
    this.assetId = this.route.snapshot.paramMap.get('assetId');
    this.router.navigateByUrl('EditAsset/' + this.assetId);
    this.getProjectAssetDetail();
  }

  pdf() {
    this._assetsService.getAssetDetailPDF(this.assetId).subscribe((data) => {
      const source = `data:application/pdf;base64,${data}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = 'ASSETDETAIL.pdf';
      link.click();
    });
  }

  sendEmail() {
    this.dialog.open(SendemailComponent, {
      width: '550px',
      data: { fileName: FILE_NAMES.ASSET_DETAIL_ATTACHMENT, isAssetDetailPdf: true, isFromPayApplication: false, assetId: this.assetId }
    });
  }

  goBack() {
    this._helperService.setTabString('RFID');
    this._helperService.setChildString('warrantydocuments');
    this._location.back();
  }
}
