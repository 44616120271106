<!-- begin login -->


<div class="login bg-black animated fadeInDown" [hidden]="isAuthorized">
  <!-- begin brand -->
  <div class="login-header">
    <div class="brand">
      <span class="logo"></span>
    </div>
    <!-- <div class="icon">
            <i class="fa fa-lock"></i>
        </div> -->
  </div>
  <!-- end brand -->
  <!-- begin login-content -->
  <div class="login-content">
    <small class="brand-green mb-4 d-block start"> Sign in to start.. </small>
    <div *ngIf="isShowError" class="red-text center error-message d-flex align-items-center">
      <i class="material-icons">error</i>
      <p class="mb-0 ml-2">{{errorMessage}}</p>
    </div>
    <form #f="ngForm" (ngSubmit)="formSubmit()" class="margin-bottom-0">
      <div class="form-group m-b-20">
        <input type="text" [(ngModel)]="username" name="username" class="form-control form-control-lg inverse-mode"
          placeholder="Email" required />
      </div>
      <div class="form-group m-b-20" style="position: relative;">
        <!-- <input type="password" [(ngModel)]="password" name="password" class="form-control form-control-lg inverse-mode"
          placeholder="Password" required /> -->

        <input [type]="hide ? 'password' : 'text'" [(ngModel)]="password" name="password"
          class="form-control form-control-lg inverse-mode" placeholder="Password" required>
        <mat-icon style="position: absolute; right: 10px;top: 28%;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </div>
      <div class="login-buttons">
        <button type="submit" class="btn btn-success btn-block btn-lg" [disabled]="buttonClicked">Sign me in
          <i class="fas fa-spinner fa-spin button-loader ml-4" *ngIf="buttonClicked"></i>
        </button>
      </div>
      <div class="mt-4">
        <a [routerLink]="['../forgotpassword']" class="brand-orange">Forgot Password</a>
      </div>

    </form>
  </div>
  <!-- end login-content -->
</div>
<!-- end login -->