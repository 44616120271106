<div class="container container-style">
    <div class="mb-3 mt-3">
        <h4 class="pb-2  mb-0"> Cost Event </h4>
    </div>
    <mat-divider class="devider margin-top"></mat-divider>
    <form class="form" [formGroup]="filterForm">
        <div class="row align-items-start mt-5 margin">
            <mat-checkbox class="checkbox mt-3" name="isVirtual" (change)="virtualPrograms($event)"
                *ngIf="programVisible">Virtual Program
            </mat-checkbox>
            <mat-checkbox class="checkbox mt-3" name="isVirtual" (change)="virtualProgramUnchecked()" [checked]="true"
                *ngIf="isVPvisible">Virtual Program
            </mat-checkbox>
            <div class="col-md-3 mt-2 campus-report add-campus kpi-dropdown p-relative"  *ngIf="programVisible">
                <ng-multiselect-dropdown formControlName="programNames" #programSelection
                    [placeholder]="'Select Program'"
                    [settings]="programDropdownSettings" [data]="programList"
                    ngModelOptions="{standalone: true}" multiple="false" [(ngModel)]="programSelect"
                    (onSelect)="onSelectProgram($event)" (onSelectAll)="onSelectAllProgram($event)"
                    (onDeSelectAll)="onDeSelectAllProgram($event)" (onDeSelect)="onDeSelectProgram($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-4 mt-2 campus-report add-campus kpi-dropdown p-relative" *ngIf="isVPvisible">
                <label class="custom-multiple-label">Select Virtual
                    Program</label>
                <ng-multiselect-dropdown formControlName="programNames" #programSelection
                    [settings]="programDropdownSettings"
                    [data]="virtualProgramList" ngModelOptions="{standalone: true}" multiple="false"
                    [(ngModel)]="programSelect" (onSelect)="onSelectVirtualProgram($event)"
                    (onSelectAll)="onSelectAllVirtualProgram($event)"
                    (onDeSelectAll)="onDeSelectAllVirtualProgram($event)"
                    (onDeSelect)="onDeSelectVirtualProgram($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-3 mt-2 campus-report add-campus kpi-dropdown p-relative" *ngIf="programVisible">
                <label class="custom-multiple-label"
                    *ngIf="this.filterForm.value.projectNames && this.filterForm.value.projectNames.length>0">Select
                    Project</label>
                <ng-multiselect-dropdown class="project-dropdown" formControlName="projectNames" #projectSelection
                    [placeholder]="'Select Project'" [settings]="projectDropdownSettings" [data]="projectlist"
                    ngModelOptions="{standalone: true}" multiple="false" (onSelect)="onSubmit($event)" (onSelectAll)="onSelectAllProject($event)"
                    (onDeSelectAll)="onDeSelectAllProject($event)" (onDeSelect)="onDeSelectProject($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-3 mt-2 campus-report add-campus kpi-dropdown p-relative" *ngIf="isVPvisible">
                <label class="custom-multiple-label"
                    *ngIf="this.filterForm.value.projectNames && this.filterForm.value.projectNames.length>0">Select
                    Project</label>
                <ng-multiselect-dropdown class="project-dropdown" formControlName="projectNames" #projectSelection
                    [placeholder]="'Select Project'" [settings]="projectDropdownSettings" (onSelect)="onSubmit($event)"
                    [data]="alreadySelectedVirtualProjects" ngModelOptions="{standalone: true}" multiple="false"
                    (onSelectAll)="onSelectAllProject($event)" (onDeSelectAll)="onDeSelectAllProject($event)"
                    (onDeSelect)="onDeSelectProject($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>
    </form>
</div>