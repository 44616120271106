<mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)">
    <mat-tab>
        <ng-template id="warrrantyHistory" mat-tab-label>
            <mat-icon></mat-icon>
            Warrranty History
        </ng-template>
        <div class="row">
            <div class="col-md-12 pr-0">
                <app-history *ngIf="selectedTabIndex === 0"></app-history>
            </div>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template id="warrantyRepairClaimHistory" mat-tab-label>
            <mat-icon></mat-icon>
            Warranty / Repair Claim History
        </ng-template>
        <div class="row">
            <div class="col-md-12 pr-0">
                <app-claim *ngIf="selectedTabIndex === 1"></app-claim>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>

