export class SearchModel {
  public PageNumber = 0;
  public PageSize = 10;
  public SortBy = '';
  public SortOrder = 0;
  public TotalPages = 1;
  public TotalCount = 10;
  public SearchText = '';
  public Filter = -1;
  public ViewType = 0;
  public Id = '';
  public IsActive?: boolean;
}