import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    @Output() status = new EventEmitter<boolean>();

    display(value: boolean) {
        this.status.emit(value);
    }
}
