export const FILE_NAMES = {
  MASTER_PROGRAM: 'master program',
  MASTER_CONTRACT_TYPE: 'contract type',
  MASTER_COST_EVENT_TYPE: 'cost event type',
  MASTER_FILE_CATEGORY: 'file category',
  MASTER_FUNDING_SOURCES: 'funding sources',
  MASTER_FUNDING_SOURCES_CATEGORY: 'funding sources category',
  MASTER_INVOICE_CATEGORY: 'invoice category',
  MASTER_TERRITORY: 'territory',
  MASTER_PROJECT_TYPE: 'project type',
  MASTER_COST_EVENT_REASON: 'cost event reason',
  MASTER_SERVICE_TYPE: 'service type',
  MASTER_ORGANIZATION: 'Organization',
  PUNCHLIST_ITEMS: 'punchlist items',
  REPORTS_COCR_LOG: 'co/cr log',
  REPORTS_DOC_REVIEW_LOG: 'doc review log',
  REPORTS_ISSUES_LOG: 'issues log',
  REPORTS_PROJECT_MONTHLY_REPORT: 'project monthly report',
  REPORTS_PM_ACTIVITY_REPORT: 'pm activity report',
  REPORTS_PM_ASSIGNMENT: 'pm assignment',
  REPORTS_WEEKLY_REPORT: 'weekly report',
  COST_EVENTS: 'cost events',
  PROJECT_LIST: 'project list',
  AGED_KPI: 'aged kpi',
  ASSETS: 'assets',
  ASSETS_CATEGORY: 'assets category',
  ASSETS_TYPE: 'asset type',
  BALL_IN_COURT_REPORT: 'ball in court report',
  BUILDING_INFO: 'building info',
  CAMPUS_FACILITIES: 'campus facilities',
  COST_SCHEDULE_IMPACT: 'cost schedule impact',
  COST_EVENT_KPI: 'cost event kpi',
  COST_EVENT_KPI_LIST: 'cost event kpi list',
  DEPARTMENT: 'department',
  ENTITY_CONFIG: 'entity config',
  FLOOR_FACILITIES: 'floor facilities',
  FUND_PERFORMANCE_KPI: 'fund performance kpi',
  FUND_PERFORMANCE_LIST: 'fund performance list',
  ASSIGN_TO_ME: 'assign to me',
  ASSIGN_BY_ME: 'assign by me',
  INVOICE_KPI: 'invoice kpi',
  INVOICE_KPI_LIST: 'invoice kpi list',
  ISSUE_KPI: 'issue kpi',
  ISSUE_KPI_LIST: 'issue kpi list',
  PROGRAM_KPI: 'program kpi',
  PROGRAM_KPI_LIST: 'program kpi list',
  QUANTITY_ISSUED_KPI: 'quantity issued kpi',
  QUANTITY_ISSUED_KPI_LIST: 'quantity issued kpi list',
  QUANTITY_SUMMARY_BY_MONTH: 'quantity summary by month',
  QUANTITY_MONTHLY_REPORT_LIST: 'quantity monthly report list',
  QUANTITY_SUMMARY_CSI_DIVISION: 'quantity summary csi division',
  ROOM_INFO: 'room info',
  SITE_INFO: 'site info',
  STATUS_REPORT: 'status report',
  STAUTS_TYPE: 'status type',
  TASK_ITEM: 'task item',
  UNIT: 'unit',
  WING_FACILITIES: 'wing facilities',
  ZONE_FACILITIES: 'zone facilities',
  BUDGET_LINE_ITEM_SUMMARY: 'budget line item summary',
  BILLING: 'billing',
  ISSUES: 'issues',
  PROGRAMS: 'programs',
  CM_GC_CODE: 'cm-gc code',
  PROJECT_COST_COUNT: 'project cost count',
  PROJECTS: 'projects',
  SCHEDULE_TARGET: 'schedule target',
  TODO: 'todo',
  USER_TASK: 'user task',
  AUDIT: 'audit',
  CUSTOMER_INFO: 'customer info',
  CUSTOMERS: 'customers',
  PRODUCT_USERS: 'product users',
  SIGNUPS: 'signups',
  ASI_LIST: 'asi list',
  PRE_DOCUMENT_LIST: 'pre document list',
  RFI_LIST: 'rfi list',
  SUBMITTALS_LIST: 'submittals list',
  BUDGET: 'budget',
  CECHANGE_ORDER: 'ce change order',
  CECONTINGENCY: 'ce contingency',
  VIEW_CONTACT: 'view contact',
  CONTRACTCE: 'contractce',
  FILES: 'files',
  INVOICE: 'invoice',
  VIEW_EDIT_INVOICE: 'view edit invoice',
  INVOICE_LIST: 'invoice list',
  ISSUES_LIST: 'issues list',
  VIEW_ISSUE_LIST: 'view issue list',
  PROJECT_INFORMATION: 'project information',
  VIEW_PROJECT_INFO: 'view project info',
  GUEST_PUNCHLIST: 'guest punchlist',
  PROJECT_FOR_GUEST: 'project for guest',
  SCHEDULE: 'schedule',
  VIEW_STATUS_REPORT: 'view status report',
  TASK_LIST_BY_PROJECT: 'task list by project',
  TASK_MANAGEMENT_LIST: 'task management list',
  UPLOAD: 'upload',
  PERMISSION: 'permission',
  ROLES: 'roles',
  USERS: 'Contacts',
  COST_EVENT_DETAILS: 'cost events detials',
  MASTER_LOCATION: 'locations',
  DOCUMENT_DETAILS: 'documents details',
  VIEW_EDIT_ISSUES: 'view edit issues',
  TIMESHEET_LIST: 'timesheet list',
  TIMESHEETINVOICE_LIST: 'invoice list',
  ADHOC_LIST:"adhoc list",
  PRIORITY_LIST:"priority list",
  CONTEXT_LIST:"context list",
  KEY_DATE_LIST:"Key Dates",
  CLOSEOUT_ITEM_KPI: 'aged kpi',
  PAY_APPLICATION_ATTACHMENTS: 'Pay Application Attachments',
  CLOSE_OUT_ATTACHMENT: 'Close Out Attachment',
  CCR_ATTACHMENT: 'CCR Attachment',
  ASSET_DETAIL_ATTACHMENT: 'Asset Details Attachment',
  ASSET_WARRANTY_CLAIM_DETAIL_ATTACHMENT: 'Warranty Claim Details Attachment',
  PROJECT_ASSETS: 'Project Assets',
  WARRANTY_HISTORY: 'Warranty History',
  PENDING_COSTEVENTS: 'Pending Costevents',
  ORGANIZATION_DETAIL: 'Organization Detail',
  PURCHASE_REQUEST: 'Purchase Request',
  PAYMENT_REQUEST: 'Payment Request'
}
