
<div>

  <div class="row" style="margin-bottom: 25px;">
    <div class="col-lg-12">
      <span class="page-header">Punch List Items</span>
      <div class="row float-right">
        <div class="filter_btn">
        </div>
        <input type="text" class="filter" id="searchbox" pInputText placeholder="Search" [(ngModel)]="searchInput"
          >
          <button *ngIf="PunchList_Create" class="btn-share" mat-flat-button color="primary"
          style="background-color: #00acac;margin-right: 15px;margin-top: 0px !important;
          margin-left: 0px;" (click)="addpunchlist()">
          Create
        </button>

        <!-- <button *ngIf="PunchListItem_Complete_Edit" class="btn-share" mat-flat-button color="primary"
          style="background-color: #00acac;margin-right: 15px;">
          Mark as Complete
        </button> -->
        <div class="dropdown">
          <div class="actionSelect">
            <select id="exportLink" (click)="sendMail($event.target.value)">
              <option selected hidden>Share</option>
              <option id="csv">CSV</option>
              <option id="excel">EXCEL</option>
              <option id="pdf">PDF</option>
              <option id="print">PRINT</option>
              <option id="copy">COPY</option>
              <option id="email">EMAIL</option>
            </select>
          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-inverse">
        <div class="panel-body p-0">
          <table datatable id="dt-table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table table-bordered">
            <thead>
              <tr>
                <th> Title </th>
                <th> Room/Location </th>
                <th> Created Date </th>
                <th> Status </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of punchList ">
                <td>
                  <a [routerLink]="['view-punch-list-items/', item.punchListItemID]" >{{item.pL_ItemName}}</a>
                </td>
                <td>{{item.pL_Room_Location}}</td>
                <td>{{item.createdOn | date:'MM/dd/yyyy'}}</td>
                <td>{{item.status}}</td>
                <td style="display: flex;">
                  <button *ngIf="PunchList_Create" class="btn btn-primary" style="font-weight: 400;" (click)="editPunchList(item.punchListItemID)">Edit</button>
                  <button class="btn btn-primary" style="background-color: #00acac;margin-left: 15px;font-weight: 400;" (click)="viewPunchlist(item.punchListItemID)">View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<script src="./../../../../assets/external/jszip.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.32/pdfmake.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.32/vfs_fonts.js"></script>
<script src="https://cdn.datatables.net/buttons/1.5.1/js/buttons.html5.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.5.1/js/buttons.print.min.js"></script>
