export class WarrantyClaimSearchModel {
  public PageNumber = 0;
  public PageSize = 10;
  public SortBy = null;
  public SortOrder = 0;
  public TotalPages = 1;
  public TotalCount = 10;
  public SearchText = "";
  public Filter = null;
  public ViewType = 0;
  public ProjectId: string;
}