
import { catchError, finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from '@app/core/core-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private requestCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor(private router: Router,
    private loaderService: LoaderService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes("login")) {
      this.loaderService.display(true);
    }
    const idToken = localStorage.getItem('id_token');

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + idToken)
      });
      this.requestCount.next(this.requestCount.value + 1);
      return next.handle(cloned).pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              // Unauthorized error, clear local storage and navigate to error route
              localStorage.clear();
              this.router.navigate(['error']);
            }
          }
          return throwError(error);
        }),
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Handle HttpResponse
            // You can perform any additional handling for successful responses here
          }
        }),
        finalize(() => {
          // Decrement the request count when the request is completed
          this.requestCount.next(this.requestCount.value - 1);
          // If all requests are completed, hide the loader
          if (this.requestCount.value === 0) {
            this.loaderService.display(false);
          }
        })
      );
    } else {
      return next.handle(req)
        .pipe(
          catchError((error: any) => {
            this.loaderService.display(false);
            return throwError(error);
          }),
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.loaderService.display(false);
            }
          }),
        );
    }
  }
}
