<div class="row">
  <div class="col-sm-12 col-md-12 no-padding">
    <h4 class="mb-0" *ngIf="!this.data.isAllowedToEdit">Add New Claim</h4>
    <h4 class="mb-0" *ngIf="this.data.isAllowedToEdit">Edit Claim</h4>
  </div>
  <hr>
</div>
<form [formGroup]="claimForm" (ngSubmit)="onsubmit()">
  <div class="col-12 no-padding d-flex">
    <div class="col-6 padding-left margin-top">
      <ng-multiselect-dropdown [placeholder]="'Asset*'" [settings]="assetListSetting" [data]="availableAssets"
        [(ngModel)]="selectedAsset" formControlName="assetId" class="custom-dropdown">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-6 no-padding mt-2">
      <mat-form-field appearance="fill">
        <mat-label>Assignee</mat-label>
        <input matInput formControlName="assignee" required>
      </mat-form-field>
    </div>
  </div>
  <div class="row no-padding">
    <div class="col-6 padding-left">
      <mat-form-field appearance="fill">
        <mat-label> Claim Type</mat-label>
        <mat-select formControlName="type" required  (selectionChange)="onClaimTypeChange($event.value)">
          <mat-option [value]="0">Repair</mat-option>
          <mat-option [value]="1">Warranty</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6 no-padding">
      <mat-form-field appearance="fill">
        <mat-label>Claim Number</mat-label>
        <input matInput formControlName="claimId" required [readonly]="isAddedFromHistory || isMaintenanceManager">
      </mat-form-field>
    </div>
  </div>
  <div class="col-12 no-padding">
    <mat-form-field appearance="fill">
      <mat-label>Claim Description</mat-label>
      <textarea matInput formControlName="description" rows="2" required></textarea>
    </mat-form-field>
  </div>
  <div class="row no-padding">
    <div class="col-6 padding-left">
      <mat-form-field appearance="fill">
        <mat-label>Due Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dueDate" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-6 padding-left">
      <mat-form-field appearance="fill">
        <mat-label>Cost</mat-label>
        <input matInput formControlName="repairCost" >
      </mat-form-field>
    </div>
  </div>
  <!-- File Upload Section -->
  <div class="fileUpload row mt-3">
    <div class="image-picker-wrapper col-6">
      <div class="image-picker1">
      </div>
      <input name="imageUrl" capture="camera" class="form-control-file min-width" name="picture" type="file"
        accept="image/*" (change)="handleInputChange($event)" multiple />
    </div>
    <div class="image-wrapper col-6 max-height">
      <span *ngFor="let url of previewURL; let i = index">
        <img [src]="url" class="imege-select rounded" height="90">
        <span class="delete-image" (click)="imageClick(i)">+</span>
      </span>
    </div>
  </div>
  <!-- End File Upload Section -->
  <div class="col-6"></div>
  <div class="mt-4" align="end">
    <button  class="btn btn-primary ml-2 ng-star-inserted min-width add-margin" type="submit" [disabled]="claimForm.invalid">Submit</button>
    <button class="btn btn-secondary ml-2 min-width cancel-margin" (click)="cancel()">Cancel</button>
  </div>
</form>
