import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetsService } from '@app/pages/master/assets/assets.service';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { SendemailComponent } from '@app/pages/GeneralItems/sendemail/sendemail.component';
import { FILE_NAMES } from '@app/pages/GeneralItems/sendemail/fileNames.helper';
import * as Function from '../../../../../../../shared/common/function';
require('jspdf-autotable');

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

export class HistoryComponent implements OnInit {
  assetId = "";
  warrantyArray = [
    {
      warrantyEndDate: "",
      warrantyStartDate: "",
      cost: 0,
      type: null
    },
  ];
  assetDetailWarranties = [];
  warrantyStartDate = "";
  warrantyEndDate = "";
  function = Function;
  searchInput = '';

  constructor(private route: ActivatedRoute, private _assetService: AssetsService, private datePipe: DatePipe, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAssetWarrantyHistory();
  }

  getAssetWarrantyHistory() {
    const assetId = this.route.snapshot.paramMap.get('assetId') || "";
    if (assetId) {
      this._assetService.getAssetWarrantyHistory(assetId).subscribe(list => {
        this.assetDetailWarranties = list?.assetWarrantyHistory || [];
        this.warrantyArray = this.assetDetailWarranties['warranties'] || [];
        if (this.warrantyArray && this.warrantyArray.length > 0) {
          this.warrantyArray.forEach(element => {
            element['type'] = element['type'] === 0 ? 'Manufacturer' : 'Extended';
          });
        }
      });
    }
  }

  pdf() {
    const finalList = this.getFinalList(); // Assuming this returns an array of objects with StartDate, EndDate, Cost, and Type fields
    const currentdate = new Date();
    const datetime = (currentdate.getMonth() + 1) + '/' + currentdate.getDate() + '/' + currentdate.getFullYear();
    const runDate = 'Run Date: ' + this.datePipe.transform(datetime, 'MM/dd/YYYY');
    let doc = new jsPDF('l', 'pt');
    doc.setFontSize(5);
    let columns = ["Type", "Start Date", "End Date", "Cost"]; // Include Type in columns
    let rows = [];
    var headerFooter = function (data) {
      doc.setFontSize(9);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.text('\t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t' + runDate +
        '\n' + 'Warranty History', data.settings.margin.left, 50);
    };

    var options = {
      beforePageContent: headerFooter,
      headerStyles: {
        fontSize: 9
      },
      bodyStyles: {
        fontSize: 8
      },
      margin: {
        top: 80
      },
    };
    for (let i = 0; i < finalList.length; i++) {
      // Push each row, including Type field
      rows.push([
        finalList[i].Type, // Assuming Type is 0 or 1
        finalList[i].Start_Date,
        finalList[i].End_Date,
        finalList[i].Cost
      ]);
    }

    (doc as any).autoTable(columns, rows, options);
    let totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setFontSize(9);
      doc.setTextColor(40);
      doc.setPage(i);
      doc.text(750, 70, 'Page :' + i + ' of ' + totalPages);
      if (i == totalPages) {
        doc.text(210, 820, 'IFM360 Program Management Module');
      }
    }
    doc.save('WarrantyHistory.pdf');
  }

  getFinalList() {
    let finalList = [];
    for (let i = 0; i < this.warrantyArray.length; i++) {
      if (this.warrantyArray[i].warrantyEndDate === null) {
        this.warrantyArray[i].warrantyStartDate = '';
      }
      if (this.warrantyArray[i].warrantyEndDate === null) {
        this.warrantyArray[i].warrantyEndDate = '';
      }
      if (this.warrantyArray[i].type === null) {
        this.warrantyArray[i].type = '';
      }
      if (this.warrantyArray[i].cost === null) {
        this.warrantyArray[i].cost = 0
      }
      finalList.push(
        {
          Type: this.warrantyArray[i]?.type,
          Start_Date: this.datePipe.transform(this.warrantyArray[i]?.warrantyStartDate, 'MM/dd/YYYY'),
          End_Date: this.datePipe.transform(this.warrantyArray[i]?.warrantyEndDate, 'MM/dd/YYYY'),
          Cost: this.function.formatNegativeNumber(this.warrantyArray[i]?.cost)
        }
      );
    }
    return finalList;
  }

  exportData(format: 'csv' | 'excel'): void {
    const element = document.getElementById('docreview');
    const ws: WorkSheet = utils.table_to_sheet(element);
    // Set cell format for the entire 'desired' column to percentage
    const range = utils.decode_range(ws['!ref']);

    for (let i = range.s.r + 1; i <= range.e.r; i++) {
      let cellRef = utils.encode_cell({ r: i, c: 3 });
      for (let index = 3; index <= 3; index++) {
        cellRef = utils.encode_cell({ r: i, c: index }); // 'Q' column
        if (ws[cellRef]) {
          if (ws[cellRef]?.v > 0) {
            ws[cellRef].v = '$' + `${this.function.formatNegativeNumber(ws[cellRef].v)}`;
            ws[cellRef].z = '($0.00)'; // '$0.00' represents the currency format
          }
          else
            ws[cellRef].z = '$0.00'; // '$0.00' represents the currency format
        }
      }
    }
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    if (format == 'csv')
      writeFile(wb, 'WarrantyHistoryExcelSheet.csv');
    else
      writeFile(wb, 'WarrantyHistoryExcelSheet.xlsx');
  }

  onPreview() {
    const finalList = this.getFinalList();
    let printContents = '';
    const WindowObject = window.open('', 'PrintWindow', 'width=auto,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'font-size: 8px;' +
      'border:1px solid #000;' +
      '}' +
      'th, td {' +
      'text-align:center;' +
      '}' +
      '</style>';
    printContents += `<table>
                   <tr>
                     <th>Type</th>
                     <th>Start Date</th>
                     <th>End Date</th>
                     <th>Cost</th>
                   </tr>`;
    printContents += htmlToPrint;
    finalList.map(data => {
      printContents += `<tr>
                     <td>${data.Type}</td>
                     <td>${data.Start_Date}</td>
                     <td>${data.End_Date}</td>
                     <td>${data.Cost}</td>
                   </tr>`;
      return printContents;
    });
    const htmlData = `<html>
        <head>
          <title>OFM</title>
        </head>
        <body>${printContents}</body>
      </html>`;
    WindowObject.document.writeln(htmlData);
    WindowObject.print();
    WindowObject.document.close()
    WindowObject.focus()
    setTimeout(() => {
      WindowObject.close();
    }, 5000);
  }

  copy() {
    const element = document.querySelector('#docreview');
    this.selectNode(element);
  }

  selectNode(node) {
    let range = document.createRange();
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
    document.execCommand('copy');
  }

  sendMail() {
    const finalList = this.getFinalList();
    let header = "Warranty History Information";
    const resultArray = Object.keys(finalList).map(index => {
      let listdata = finalList[index];
      return listdata;
    });
    const sendMailModal = this.dialog.open(SendemailComponent, {
      width: '550px',
      data: { fileName: FILE_NAMES.WARRANTY_HISTORY, isWarrantyHistoryPdf: true, selectedData: resultArray, header }
    });
    sendMailModal.afterClosed().subscribe(() => {
    })
  }
}
