<div class="row mt-3">
  <div class="col-6">
    <h4></h4>
  </div>
  <div class="col-6">
    <div class="right_content mr-3 mb-2">
      <div class="share_btn mr-3">
        <button class="btn_share mr-3" mat-flat-button color="primary" [matMenuTriggerFor]="menu">Share <i
            class="fa fa-angle-down"></i></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" class="btn-share-option" pButton iconPos="left" (click)="pdf()">
            PDF
          </button>
          <button mat-menu-item type="button" class="btn-share-option" pButton iconPos="left" (click)="sendEmail()">
            Email
          </button>
        </mat-menu>
      </div>
      <button type="button" class="btn btn-secondary btn-back-style back-button" (click)="goBack()">Back</button>
    </div>
  </div>
</div>
<div class="row cwd mt-4">
  <div class="col-12">
    <h4 class="mt-2">Asset Details</h4>
  </div>
</div>
<div class="row d-flex mt-2 mb-4">
  <div class="col-4 mt-2"><b>Asset Name</b> : {{assetDetail['assetName']}} </div>
  <div class="col-4 mt-2"><b>Asset Category</b> : {{assetDetail['assetCategory']}} </div>
  <div class="col-4 mt-2"><b>Campus</b> : {{assetDetail['campus']}}</div>
  <div class="col-4 mt-2"><b>Department</b> : {{assetDetail['department']}}</div>
  <div class="col-4 mt-2"><b>Model No</b> : {{assetDetail['modelNo']}}</div>
  <div class="col-4 mt-2"><b>Serial No</b> : {{assetDetail['serialNo']}}</div>
  <div class="col-4 mt-2"><b>Amount</b> : {{ assetDetail['amount'] | currency}}</div>
  <div class="col-12 mt-4 cwd">
    <h4 class="mt-2">Current Warranty Details</h4>
  </div>
  <div *ngIf="assetDetail['warranties']?.length > 0; else noData">
    <div class="row mt-4 mb-2" *ngFor="let warranty of assetDetail['warranties']">
      <div class="col-4 mt-2"><b>Warranty Start Date</b> : {{warranty?.warrantyStartDate | date}}</div>
      <div class="col-4 mt-2"><b>Warranty End Date</b> : {{warranty?.warrantyEndDate | date}}</div>
      <div class="col-4 mt-2"
          [ngClass]="{'positive-value': warranty?.cost >= 0, 'negative-value': warranty?.cost < 0}">
        <b>Warranty Cost</b> :
        <span *ngIf="warranty?.cost >= 0">$</span>{{ warranty?.cost }}
      </div>
      <div class="col-4 mt-2"><b>Warranty Type</b> : {{ warranty?.type === 0 ? 'Manufacturer' : warranty?.type === 1 ? 'Extended' : '' }} </div>
      <div class="col-4 mt-2"></div>
      <div class="col-4 mt-2">
        <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="warranty.websiteLink"
          cssClass="bshadow"></ngx-qrcode>
      </div>
      <hr>
    </div>
  </div>
  <ng-template #noData>
    <div class="col-12 mt-4 text-center">
      <h5>No data available</h5>
    </div>
  </ng-template>
</div>
<div class="margin-top">
  <app-warranty-history></app-warranty-history>
</div>
