import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CoreHttpService } from '../../../core/core-http.service';

@Injectable({
  providedIn: 'root'
})

export class PunchlistService {
  clientNumber: string;

  constructor(private coreHttpService: CoreHttpService) { }

  // GET PUNCHlIST DATA
  getpunchListItems(ProjectID: string) {
    const body = new HttpParams()
        .set('ProjectID', ProjectID);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'PunchList/getProjectPunchList/', body
    );
  }


  // ADD INVITE USER
  addInviteUser(
    ProjectID: string,
    firstName: string,
    lastName: string,
    email: string,
  ) {
    this.clientNumber = localStorage.getItem('clientNumber');
    const body = new HttpParams()
      .set('ProjectID', ProjectID)
      .set('FirstName', firstName)
      .set('LastName', lastName)
      .set('Email', email)
      .set('ClientNumber', this.clientNumber);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'PunchList/InviteUser/', body
    );
  }


  // ADD PUNCHLIST ITEM
  addPunchList(data) {
    const params = new HttpParams()
      .set('ProjectID', data.pId)
      .set('PL_ItemName', data.itemName)
      .set('PL_Room_Location', data.location)
      .set('PL_Description', data.description);
    return this.coreHttpService.httpPostRequestWithParamsWithoutLoader(environment.apiBaseUrl + 'PunchList/AddPunchList', params);
  }


  // EDIT PUNCHLIST ITEMS
  editPunchList(data) {
    const params = new HttpParams()
      .set('ProjectID', data.pId)
      .set('PunchListItemID', data.punchListId)
      .set('PL_ItemName', data.itemName)
      .set('PL_Room_Location', data.location)
      .set('Status', data.status)
      .set('IsApproved', data.isApproved)
      .set('PL_Description', data.description);
    return this.coreHttpService.httpPutRequest(environment.apiBaseUrl + 'PunchList/EditPunchList', params);
  }


  // ADD FILES FOR PUNCHLIST ITEM
  addFiles(punchListItemId, formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequest(environment.apiBaseUrl + 'PunchList/AddPunchList/' + punchListItemId + '/AddFiles',
      formData,
      { params, headers });
  }

  // REMOVE FILE
  removeFile(fieldId) {
    const params = new HttpParams()
    .set('FileID', fieldId);
    return this.coreHttpService.httpPutRequest(environment.apiBaseUrl + 'PunchList/InActivePunchFiles/' , params);
  }



  // ALL PUNCHLIST
  getAllpunchListItems() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'PunchList/GetAllPunchListItemByProjectManager'
    );
  }

  // GET PUNCHLIST BY ID
  getPunchListbyId(PunchListItemID: string) {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'PunchList/GetPunchlistByID/' + PunchListItemID);
  }


  // GET PUNCHLIST FILES
  addPunchListfiles(
    PunchListItemID: string
  ) {
    const body = new HttpParams()
      .set('PunchListItemID', PunchListItemID);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'PunchList/AddPunchList/' + 'PunchListItemID' + '/AddFiles' , body
    );
  }

  // ISAPPROVED LIST
  isApprovedList(
    PunchListItemID: any
  ) {
    const body = new HttpParams()
      .set('punchListID', PunchListItemID);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'PunchList/PunchListMarkAsCompleted', body
    );
  }



  // GET GUEST PUNCHLIST ITEMS
  getGuestpunchlist(
    projectID: string) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'PunchList/PunchListByUserID/' + projectID
    );
  }
  // MARK AS COMPLETED LIST
  isMarkAsCompleted(
    PunchListItemID: any
  ) {
    const body = new HttpParams()
      .set('PunchListID', PunchListItemID);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'PunchList/PunchListStatus/', body
    );
  }

  // ADD COMMENT
  addPunchListComment(
    PunchListItemId: string,
    Comment: string
  ): Observable<any> {
    const body = new HttpParams()
      .set('PunchListItemId', PunchListItemId)
      .set('Comment', Comment);
    return this.coreHttpService.httpPostRequest(environment.apiBaseUrl + 'PunchList/Punch/AddComment', body);
  }

  // ADD COMMENT FILES
  addCommentFiles(punchListItemId, commentId , formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequest(environment.apiBaseUrl + 'PunchList/AddPunchList/' + punchListItemId + '/' +  commentId + '/AddCommentFiles',
      formData,
      { params, headers });
  }

  // PROJECTS FOR GUEST-USER
  getProjectsForGuest(): Observable<any> {
      return this.coreHttpService.httpGetRequest(
        environment.apiBaseUrl + 'PunchList/GetProjectsforGuestUser'
      );
    }
}
