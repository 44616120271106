import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreHttpService } from '../../core/core-http.service';
import { IssuesResponse, ProgramInfoResp, ProgramNameResponse, Projects, ScheduleTargets } from '../dashboard/dashboardModel';
import { ProgramListResponse } from '../master/master.model';
import { budgetLineInfo } from '../reports/budget-line-item-summary/budgetModel';
import { API_ENDPOINTS } from '../projects/Task-management/constants';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
  })
};
@Injectable()
export class DashboardService {
  public currentDate;
  meetingId = new BehaviorSubject<string>('');
  isTaskCreatedFromMeeting = new BehaviorSubject(false);
  isTaskCreateFromMeeting = new EventEmitter<boolean>();
  
  constructor(
    private coreHttpService: CoreHttpService,
    private http: HttpClient) { }

  getScheduleTargetList() {
    this.currentDate = new Date();
    const datePipe = new DatePipe('en-US');
    // DD-MM-YYYY hh:mm
    this.currentDate = datePipe.transform(this.currentDate, 'MM-dd-yyyy');
    return this.coreHttpService.httpGetWithoutLoaderRequest<ScheduleTargets>(environment.apiBaseUrl + 'Project/ScheduleTarget/' + this.currentDate);
  }
  // Get list of issues for dashboard
  getIssuesList() {
    return this.coreHttpService.httpGetWithoutLoaderRequest<IssuesResponse>(
      environment.apiBaseUrl + 'Dashboard/OpenIssue'
    );
  }
  getBudgetLineList() {
    return this.coreHttpService.httpGetWithoutLoaderRequest<budgetLineInfo>(
      environment.apiBaseUrl + 'Report/GetBudgetItemsReport'
    );
  }
  getTodoList() {
    return this.coreHttpService.httpGetWithoutLoaderRequestForCloseLoader(environment.apiBaseUrl + 'Dashboard/ToDo');
  }
  getProjectList(): Observable<Projects> {
    // TODO: remove and pass status as parameter
    // const data = new HttpHeaders().set('Status', 'Active');
    return this.coreHttpService.httpGetWithoutLoaderRequest<Projects>(environment.apiBaseUrl + 'Dashboard/ActiveProject');
  }

  getActiveAllProject(): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest<any>(environment.apiBaseUrl + 'GetAllActiveProject');
  }

  getProgramNames() {
    return this.coreHttpService.httpGetRequest<ProgramNameResponse>(environment.apiBaseUrl + 'Program?status=null&isOnlyShortList=true');
  }

  getProgramNamesDashboard() {
    return this.coreHttpService.httpGetWithoutLoaderRequest<ProgramNameResponse>(environment.apiBaseUrl + 'Program?status=null&isOnlyShortList=true');
  }

  getProgramInfo(programNumber) {
    if (programNumber !== '') {
      return this.coreHttpService.httpGetWithoutLoaderRequest<ProgramInfoResp>(environment.apiBaseUrl + 'dashboard/project?programNumber=' + programNumber);
    } else {
      return this.coreHttpService.httpGetWithoutLoaderRequest<ProgramInfoResp>(environment.apiBaseUrl + 'dashboard/project');
    }
  }

  getUserTaskList() {
    return this.coreHttpService.httpGetWithoutLoaderRequest(environment.apiBaseUrl + 'ProjectTask/GetAllTaskforUser');
  }

  getProjectCostList(programID: any, projectID: any): Observable<any> {
    const body = {
      'programID': programID.length > 0 ? programID.join(',') : '',
      'projectID': projectID.length > 0 ? projectID.join(',') : ''
    };
    return this.coreHttpService.httpPostWithoutLoaderRequest(environment.apiBaseUrl + 'KPI/GetProjectCostCount', body, httpOptions);
  }


  getProgramList() {
    return this.coreHttpService.httpGetWithoutLoaderRequest<ProgramListResponse>(environment.apiBaseUrl + 'Program');
  }

  getProjectListdashboard(programNumber): Observable<Projects> {
    return this.coreHttpService.httpGetWithoutLoaderRequest<Projects>(environment.apiBaseUrl + 'Project?programNumber=' + programNumber);
  }
  GetSettings(): Observable<any> {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Request/GetSettings');
  }
  SaveSettings(data): Observable<any> {
    const body = new HttpParams()
      .set('data', data);
    return this.coreHttpService.httpPostRequest(environment.apiBaseUrl + 'Request/SaveSettings', body);
  }

  getTaskMeeting() {
    return this.http.get(environment.apiBaseUrl + API_ENDPOINTS.GET_TASK_MEETING)
  }

  addTaskMeeting(logData) {
    const body = new HttpParams()
      .set('scheduleDate', logData.startDate)
      .set('endDate', logData.endDate)
      .set('title', logData.title)
      .set('description', logData.description);

    return this.http.post(environment.apiBaseUrl + API_ENDPOINTS.ADD_TASK_MEETING, body);
  }

  updateTaskMeeting(logData) {
    const body = new HttpParams()
      .set('id', logData.id)
      .set('scheduleDate', logData.startDate)
      .set('endDate', logData.endDate)
      .set('title', logData.title)
      .set('description', logData.description);

    return this.http.post(environment.apiBaseUrl + API_ENDPOINTS.ADD_TASK_MEETING, body);
  }

  deleteTaskMeeting(id: string) {
    const params = new HttpParams({ fromObject: { Id: id } });

    return this.http.delete(environment.apiBaseUrl + API_ENDPOINTS.DELETE_TASK_MEETING, { params: params });
  }
}
