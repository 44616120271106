import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

  constructor() { }

  IsloggedIn() {
    return localStorage.getItem('id_token');
  }
}


