// Core Module
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

// Main Component
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopMenuComponent } from './top-menu/top-menu.component';

// Component Module
// import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { HighlightJsModule } from 'ngx-highlight-js';

// IFM Code
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { AuthInterceptor } from './http-interceptors/auth-interceptor';

// KPI
import { CoreHttpService } from './core/core-http.service';
import { LoaderService } from './core/core-loader.service';

//import modules

import { MatInputModule } from '@angular/material/input';
import { ContextMenuService, ExcelExportService, PdfExportService, SelectionService } from '@syncfusion/ej2-angular-gantt';
import { LoginModule } from './pages/login/login.module';
import { DocumentService } from './pages/projects/Additional-feature/document.service';
import { TaskManagementService } from './pages/projects/Task-management/task-management-list/task-management-list.service';
import { HelperService } from './pages/projects/punchlilst/helper.service';
import { AuthService } from './shared/auth.service';
import { AuthguardGuard } from './shared/authguard.guard';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    TopMenuComponent,
    FooterComponent],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    MatFormFieldModule,
    MaterialModule,
    AppRoutingModule,
    MatInputModule,
    BrowserAnimationsModule,
    BrowserModule,
    HighlightJsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger'
    }),
    LoginModule,
    SharedModule,
  ],
  providers: [
    CoreHttpService,
    DocumentService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthguardGuard,
    HelperService,
    TaskManagementService,
    PdfExportService,
    ExcelExportService,
    ContextMenuService,
    SelectionService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}