import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Permissions } from '../../../utils.model';
import { GeneralInfo, ProjectInfo } from '../../projectModel';
import { PunchlistService } from '../punchlist.service';

import { DatePipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { FILE_NAMES } from '../../../GeneralItems/sendemail/fileNames.helper';
import { SendemailComponent } from '../../../GeneralItems/sendemail/sendemail.component';

@Component({
  selector: 'app-guest-punchlist',
  templateUrl: './guest-punchlist.component.html',
  styleUrls: ['./guest-punchlist.component.css']
})

export class GuestPunchlistComponent implements OnInit, OnDestroy {
  @Input() punchList = [];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  edit = false;
  dtTrigger: Subject<any> = new Subject();
  appAdmin = true;
  PunchListItem_Complete_Edit = false;
  guestUser = false;
  linked = false;
  showLoader = false;
  permissions: Permissions;
  iteration = 5;

  projInfo: ProjectInfo;
  res: any;
  generalInfo: GeneralInfo;
  isProjectAllowedToEdit: any;
  clientNumber: string;
  hasPagination: boolean;
  searchInput;
  projectID: string;
  PunchList_Create = false;
  subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog, private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private punchlistService: PunchlistService) {
    this.projectID = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.clientNumber = localStorage.getItem('clientNumber');
    this.PunchList_Create = this.permissions.PunchList_Create;


    this.route.params.subscribe(res => (this.projectID = res.id));

    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.clientNumber = localStorage.getItem('clientNumber');

    var currentdate = new Date();
    this.PunchListItem_Complete_Edit = this.permissions.PunchListItem_Complete_Edit;
    var currentdate = new Date();
    var datetime = (currentdate.getMonth() + 1) + '/' + currentdate.getDate() + '/' + currentdate.getFullYear();
    var runDate = 'Run Date: ' + this.datePipe.transform(datetime, 'MM/dd/yyyy', 'en-US');
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0, 1, 2, 3]
          },
          header: true,
          footer: true,
          title: 'Punchlist Information',
          customize: function (doc) {
            doc.pageMargins = [30, 30, 30, 30];
            doc.defaultStyle.fontSize = 6;
            doc.styles.tableHeader.fontSize = 6;
            doc.styles.tableFooter.fontSize = 6;
            doc.styles.title.fontSize = 9;
            doc.styles.title.alignment = 'left';
            // doc.defaultStyle.alignment = 'right';

            // Create a footer
            doc['footer'] = (function () {
              return {
                columns: [
                  {
                    // This is the right column
                    alignment: 'center',
                    text: 'IFM360 Program Management Module',
                  },
                ],
              };
            });

            // Create a Header
            doc['header'] = (function (page, pages) {
              return {
                columns: [
                  {
                    // This is the right column
                    alignment: 'right',
                    text: [runDate + '\n' + 'Page ', { text: page.toString() }, ' of ', { text: pages.toString() }],
                    margin: [0, 10]
                  }
                ],
                margin: [30, 5]
              };
            });
            // Styling the table: create style object
            const objLayout = {};
            // Horizontal line thickness
            objLayout['hLineWidth'] = function () { return .5; };
            // Vertikal line thickness
            objLayout['vLineWidth'] = function () { return .5; };
            // Horizontal line color
            objLayout['hLineColor'] = function () { return '#aaa'; };
            // Vertical line color
            objLayout['vLineColor'] = function () { return '#aaa'; };
            // Left padding of the cell
            objLayout['paddingLeft'] = function () { return 4; };
            // Right padding of the cell
            objLayout['paddingRight'] = function () { return 4; };
            // Inject the object in the document
            doc.content[1].layout = objLayout;
          }
        },
        {
          extend: 'csv',
          exportOptions: {
            columns: [0, 1, 2, 3]
          },
        },
        {
          extend: 'excel',
          title: 'Project Type',
          exportOptions: {
            columns: [0, 1, 2, 3]
          },
          customize: function (xlsx) {
            $(xlsx.xl['styles.xml'])
              .find('numFmt[numFmtId="164"]')
              .attr('formatCode', '[$$-en-AU]#,##0.00;[Red]-[$$-en-AU]#,##0.00');
          },
        },
        {
          extend: 'copy',
          exportOptions: {
            columns: [0, 1, 2, 3]
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, 1, 2, 3]
          },
        }
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      initComplete: function () {
        const $buttons = $('.dt-buttons').hide();
        $('#exportLink').on('change', function () {
          const DropdownList = (document.getElementById('exportLink')) as HTMLSelectElement;
          if (DropdownList.selectedIndex !== 0) {
            var btnClass = $(this).find(":selected")[0].id
              ? '.buttons-' + $(this).find(":selected")[0].id
              : null;
            if (btnClass) { $buttons.find(btnClass).click() };

            setTimeout(() => {
              DropdownList.selectedIndex = 0;
            }, 1000);
          }
        });
      }
    };
    this.getGuestpunchListItems(this.projectID);

  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }


  getGuestpunchListItems(projectID: string) {
    this.hasPagination = true;
    // 
    this.subscriptions.push(this.punchlistService.getGuestpunchlist(projectID).subscribe(
      (punchlisInfo: any) => {
        this.punchList = punchlisInfo.punchListUser;
        this.dtElement.dtInstance.then((dtInstance: any) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
        // 
      },
      () => {
        console.log('error3');
      }
    ));
  }
  addpunchlist() {
    this.router.navigateByUrl('/projects/createPunchlist/' + this.projectID);
  }

  // Edit
  editPunchList(punchListItemID) {
    this.router.navigateByUrl('/projects/createPunchlist/' + this.projectID + '/' + punchListItemID);
  }

  viewPunchlist(projectID) {
    this.router.navigateByUrl('guest-user-projects/guest-punch-list-items/:id/view-punch-list-items/' + projectID);
  }
  getFinalList() {
    // Splliting header properties from list
    let finalList = [];

    for (let i = 0; i < this.punchList.length; i++) {
      finalList.push(
        {
          pL_ItemName: this.punchList[i].pL_ItemName,
          pL_Room_Location: this.punchList[i].pL_Room_Location,
          createdOn: this.punchList[i].createdOn,
          status: this.punchList[i].status
        }
      );

    }
    console.log(finalList);
    return finalList;
  }
  sendMail(value) {
    const finalList = this.getFinalList();
    let header = "Punch List Information";
    const resultArray = Object.keys(finalList).map(index => {
      let listdata = finalList[index];
      return listdata;
    });
    if (value === 'EMAIL') {
      const sendMailModal = this.dialog.open(SendemailComponent, {
        width: '550px',
        data: { fileName: FILE_NAMES.GUEST_PUNCHLIST, selectedData: resultArray, header }
      });
      sendMailModal.afterClosed().subscribe(() => {
        var DropdownList = (document.getElementById("exportLink")) as HTMLSelectElement;
        DropdownList.selectedIndex = 0;
        console.log('MODAL CLOSED!');
      })
    }
  }
}
