import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DataTablesModule } from 'angular-datatables';

import { TableModule } from 'primeng/table';
import { MaterialModule } from "../../../material.module";
import { CosteventService } from "../costevent.service";
import { CereviewLogComponent } from "./cereview-log.component";
@NgModule({
    declarations: [
        CereviewLogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,


        DataTablesModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule
    ],
    exports: [
        CereviewLogComponent
    ],
    providers: [
        CosteventService
    ],

})

export class CereviewLogModule { }