import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CoreHttpService } from '../core/core-http.service';

// export interface MenuItem {
//   id: string;
//   icon: string;
//   title: string;
//   url: string;
//   parentID: string;
//   sortOrder: string;
// }
export interface MenuItem {
  menuID: string;
  menuTitle: string;
  navigationURL: string;
  parentMenuID: string;
  menuIcon: string;
  sortOrderId: string;
}
export interface MenuResponse {
  success: string;
  message: string;
  menus: any[];
}

@Injectable()
export class SidebarService {
  url: string;
  visible: boolean;
  constructor(private coreHttpService: CoreHttpService) {
    this.visible = true;
  }

  getMenus(): Observable<MenuResponse> {
    this.url = environment.apiBaseUrl + 'User/Menu';
    return this.coreHttpService.httpGetRequest<MenuResponse>(this.url);
  }
  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }
}
