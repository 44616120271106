<style>
  .dropdown-menu>.active>a,
  .dropdown-menu>.active>a:focus,
  .dropdown-menu>.active>a:hover {
    background: #36426c !important;
  }

  .dropdown-menu>.active .media-heading {
    color: black;
  }

  .media-left {
    display: none;
  }

  .dropdown-menu-right.dropdown-menu {
    box-shadow: none;
  }
</style>

<!-- begin #header -->
<div id="header" class="header navbar-default" *ngIf="nav.visible">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <ng-container *ngIf="this.pageSidebarTwo">
      <button type="button" class="navbar-toggle pull-left" (click)="mobileSidebarToggle()">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <button type="button" class="navbar-toggle pull-right" (click)="mobileRightSidebarToggle()">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </ng-container>

    <span *ngIf="!logo">
      <img src="assets/img/logo/loading-indicator.gif" class="loading-indicator" />
    </span>

    <span class="navbar-brand" *ngIf="logo">
      <img [src]="domSanitizer.bypassSecurityTrustUrl(logo)" class="img-fluid" />
    </span>

    <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()" *ngIf="!this.pageSidebarTwo">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <!-- end navbar-header -->

  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right" style="background-color: white;">
    <li class="dropdown navbar-user" ngbDropdown style="background-color: white;">
      <a href="javascript:;" class="dropdown-toggle" mat-button [matMenuTriggerFor]="beforeMenu"
        style="background-color: white;">
        <img [src]="domSanitizer.bypassSecurityTrustUrl(profilePicture)" />
        <span class="d-none d-md-inline">{{userName}}</span>
        <b class="caret" style="background-color: white;"></b>
      </a>

      <mat-menu #beforeMenu="matMenu" yPosition="below" style="background-color: white;">
        <div style="background-color: white;">
          <a mat-menu-item href="javascript:;" class="dropdown-item" (click)="profile()"
            style="background-color: white;">Profile</a>
          
          <a mat-menu-item href="javascript:;" class="dropdown-item text-dark font-weight-bold mb-2 bg-white" *ngIf="directory_list">
            Switch Directory
          </a>

          <div *ngIf="directory_list" style="background-color: white;">
            <div mat-menu-item class="dropdown-menu media-list dropdown-menu-right bg-light role"
              ngbDropdownMenu *ngFor="let role of Roles.directory">
              <div class="media" [ngClass]="{'active': role.isDefault}" style="background-color: white;">
                <a href="javascript:;" style="background-color: white;">
                  <div class="media-left" style="background-color: white;">
                    <i class="p-2 media-object bg-silver-darker d-inline-block"
                      style="color: #2b87c4; font-weight: bold; font-style: normal;"> CA </i>
                  </div>
                  <div class="media-body" style="vertical-align: middle;" (click)="switch(role.clientID)">
                    <span class="text-dark">
                      {{ role.organizationName }}
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <a href="javascript:void(0);" mat-menu-item [matMenuTriggerFor]="vertebrates" class="dropdown-item"
            style="background-color: white;">Support</a>

          <a mat-menu-item href="javascript:;" class="dropdown-item" (click)="logout()"
            style="background-color: white;">Log Out</a>
        </div>
      </mat-menu>

      <mat-menu class="top-mat-menu bg-white" [yOffset]="10" #vertebrates="matMenu"
        [overlayPanelClass]="'custom-overlay-class'">
        <a mat-menu-item class="dropdown-item" (click)="userManual()" style="background-color: white;">User manual</a>
        <span mat-menu-item class="bg-white">IFM360 version 2021.10.22(1.0.4)</span>
        <a target="_blank" mat-menu-item class="dropdown-item" (click)="releasenote()"
          style="background-color: white;">Release Notes</a>
      </mat-menu>

      <div class="dropdown-menu dropdown-menu-right" style="background-color: white;" ngbDropdownMenu>
        <a href="javascript:;" class="dropdown-item" (click)="profile()" style="background-color: white;">Profile</a>
        
        <div class="dropdown-divider" style="background-color: white;"></div>
        
        <a href="javascript:;" class="dropdown-item text-dark font-weight-bold mb-2 bg-white" *ngIf="directory_list">
          Switch Directory
        </a>

        <ul class="dropdown-menu media-list dropdown-menu-right bg-light change-directory" ngbDropdownMenu *ngIf="directory_list">
          <li class="media" *ngFor="let role of Roles.directory" [ngClass]="{'active': role.isDefault}">
            <a href="javascript:;" class="bg-white">
              <div class="media-left" style="background-color: white;">
                <i class="p-2 media-object bg-silver-darker d-inline-block"
                  style="color: #2b87c4; font-weight: bold; font-style: normal;"> CA </i>
              </div>
              <div class="media-body v-align" (click)="switch(role.clientID)">
                <h6 class="media-heading">
                  {{ role.organizationName }}
                </h6>
              </div>
            </a>
          </li>
        </ul>

        <div class="dropdown-divider mt-0" *ngIf="directory_list" style="background-color: white;"></div>
        
        <a href="javascript:;" class="dropdown-item" (click)="logout()" style="background-color: white;">Log Out</a>
      </div>
    </li>

    <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
    <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
      <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
        <i class="fa fa-th"></i>
      </a>
    </li>
  </ul>
  <!-- end header-nav -->
</div>
<!-- end #header -->
