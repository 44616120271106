
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, forkJoin as observableForkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreHttpService } from '../../core/core-http.service';
import { API_ENDPOINTS } from '../projects/Task-management/constants';
import { CeList, CostEventTypeInfo, Response, Response2, ReviewTypeList, UnmappedCEResp, VendorTypeList } from './costeventModel';

@Injectable()
export class CosteventService {
  @Output() 
  projectId$ = new EventEmitter();

  emitProjectId(projectId) {
    this.projectId$.emit(projectId);
  }
  // ceUrl = '../../../assets/sampledata.json';
  constructor(private http: HttpClient,
    private coreHttpService: CoreHttpService) {}

  CreateCostEvent(projectid, model, formData, resultArray, BudgetData, CriticalDate,FundingSourceList:any):Observable<any> {
    let headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams()
      .set('ProjectID', projectid)
      .set('CostEventTypeId', model.costEventType) // Mandatory
      .set('VendorId', model.vendorId) // Mandatory
      .set('CostEventNumber', model.documentid) // Mandatory
      .set('PONumber', model.poNumber)
      .set('ContractTypeId', model.contractType) // Mandatory
      .set('ServiceTypeId', model.serviceType) // Mandatory
      .set('RequestedAmount', model.requestamt)
      .set('ReasonForChangeId', model.reason) // Mandatory
      .set('ScheduleImpactInDays', model.scheduleimpact)
      .set('ScopeOfWork', model.scopeofwork) // Mandatory
      .set('CostEventPriorityId', model.priority)
      .set('CriticalSuccessor', model.CriticalSuccessor)
      .set('CriticalDate', CriticalDate)
      .set('ReviewRequirementList', this.formatIssues(resultArray))
      .set('ParentCostEventId', model.parentCostEventId)
      .set('BudgetItemId', BudgetData.budgetItemId)
      .set('CostEventFiles', formData)
      .set('CostEventXFundingSources',JSON.stringify(FundingSourceList))
      .set('IsAmountOverBudget',model.IsAmountOverBudget)

    if (formData) {
      return this.coreHttpService.httpPostRequestWithParamsWithoutLoader<any,any>(environment.apiBaseUrl + 'CostEvent', formData,
        headers,params
      );
    } else {
      return this.coreHttpService.httpPostRequest<any,any>(environment.apiBaseUrl + 'CostEvent', params);
    }
  }

  getCelist(filter, ProjectId, BudgetItemId) {
    if (filter === '' || filter === undefined || filter === null) {
       return this.coreHttpService.httpGetRequest<CeList>(environment.apiBaseUrl + 'CostEvent/');
    } else {
    return this.coreHttpService.httpGetRequest<CeList>(environment.apiBaseUrl + 'Project/' + ProjectId + '/BudgetItem/' + BudgetItemId + '/CostEvent/' + filter);
    }
  }

  getServiceType() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ServiceType');
  }
  getContractType() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ContractType');
  }

  getAllCEInfo(): Observable<any[]> {
      const response1 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ServiceType');
      const response2 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ContractType');
      const response3 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project/Issue/ActiveCostEventType');
      const response4 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEventReason');
      const response5 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Vendor?Status=Active');
      const response6 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEvent/Priority');
      const response7 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ProjectManager/Project');
      const response8 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEvent/ReviewType');
      return observableForkJoin([response1, response2, response3, response4, response5, response6, response7, response8]);
  }

  getReasons() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEventReason');
  }
  getAllProjects() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ProjectManager/Project');
  }
  getAllProjectsforCostEvent() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'GetAllProjects');
  }
  getAllProjectsforadhocReport() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'GetAllProjectsName');
  }
  getVendorNames() {
    return this.coreHttpService.httpGetRequest<VendorTypeList>(environment.apiBaseUrl + 'Vendor?Status=Active');
  }
  getRequestType() {
    return this.coreHttpService.httpGetRequest<CostEventTypeInfo>(
      environment.apiBaseUrl + 'Project/Issue/CostEventType'
    );
  }

  getActiveCostEventTypes() {
    return this.coreHttpService.httpGetRequest<CostEventTypeInfo>(
      environment.apiBaseUrl + 'Project/Issue/ActiveCostEventType'
    );
  }

  getUnmappedCE(ceTypeId, ProjectId) {
    return this.coreHttpService.httpGetRequest<UnmappedCEResp>(
      environment.apiBaseUrl + 'CostEvent/UnmappedCostEvent/Type/' + ceTypeId + '/Project/' + ProjectId
    );
  }

  getPriority() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEvent/Priority');
  }
  getProjects() {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project');
  }
  getReviewType() {
    return this.coreHttpService.httpGetRequest<ReviewTypeList>(environment.apiBaseUrl + 'CostEvent/ReviewType');
  }

  getEditableValues(): Observable<any[]> {
    const response1 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ServiceType');
    const response2 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'ContractType');
    const response3 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project/Issue/ActiveCostEventType');
    const response4 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEventReason');
    const response5 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Vendor?Status=All');
    const response6 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEvent/Priority');
    return observableForkJoin([response1, response2, response3, response4, response5, response6]);
}

   getProjectInfo(projectID) {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project/' + projectID);
  }

  getCeInfo(costeventID) {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'CostEvent/' + costeventID + '');
  }

  respond(formData: FormData) {
    const headers = new HttpHeaders()
      .set('Accept', 'multipart/form-data')
      .set('Content-Type', null);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'CostEvent/Review',
      formData,
      { headers }
    );
  }

  public async downloadResource(fileID: string): Promise<Blob> {
      // const tempid = 'DFBE7BF4-7BDC-4232-80A9-0822020F77BA';
      const file = await this.http
        .get<Blob>(environment.apiBaseUrl + 'Project/GetFile/' + fileID, {
          responseType: 'blob' as 'json'
        })
        .toPromise();
      return file;
  }

  // public async downloadResource(fileID: string) {
  //   // const tempid = 'DFBE7BF4-7BDC-4232-80A9-0822020F77BA';
  //   const file = await this.coreHttpService
  //     .httpGetRequest<Blob>(environment.apiBaseUrl + 'Project/GetFile/' + fileID)
  //     // .toPromise();
  //   return file;
  // }

  formatIssues(resultArray) {
    let data = '[';
    for (let i = 0; i < resultArray.length; i++) {
      data +=
        '{"ReviewTypeId":"' +
        resultArray[i].ReviewTypeId +
        '","IsRequired":"' +
        resultArray[i].IsRequired +
        '"}';
       // add a comma only if there is more than 1 record and except for the last record
       if (resultArray.length > 1 && i + 1 !== resultArray.length) {
        data += ',';
      }
    }
    data += ']';
    return data;
  }


  // this is to delete existing files and add new files
  updateFiles(costeventID, formData, filesTodelete, editType) {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams()
    .set('CostEventId', costeventID)
    .set('DeletedCostEventFiles', filesTodelete)
    .set('editType', editType);
    return this.coreHttpService.httpPutRequest(environment.apiBaseUrl + 'CostEvent' , formData, {
      params,
      headers
    });
  }

  getBudgetItems(projectId) {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project/' + projectId + '/BudgetItem');
  }
  getActiveBudgetItems(projectId) {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project/' + projectId + '/BudgetItem');
  }

  getProjectBudgetItems(projectId) {
    return this.coreHttpService.httpGetRequest(environment.apiBaseUrl + 'Project/' + projectId + '/ProjectBudgetItem');
  }
  // call this method to update CS dates or additional text
  updateCostEventInfo(
    CostEventId: string,
    ProjectID: string,
    CostEventTypeId: any,
    VendorId: string,
    CostEventNumber: string,
    ContractTypeId: any,
    ServiceTypeId: any,
    RequestedAmount: string,
    ReasonForChangeId: any,
    ScheduleImpactInDays: string,
    ScopeOfWork: string,
    CostEventPriorityId: any,
    CriticalSuccessor: string,
    CriticalDate: string,
    ParentCostEventId: string,
    budgetItemId: string,
    AssignedAmount: string,
    EditAdditionalDates: string,
    EditAdditionalTexts: string,
    EditType: any,
    FundingSourceList ?: any
  ) {
    const body = new HttpParams()
    .set('CostEventId', CostEventId)
    .set('ProjectID', ProjectID)
    .set('CostEventTypeId', CostEventTypeId)
    .set('VendorId', VendorId)
    .set('CostEventNumber', CostEventNumber)
    .set('ContractTypeId', ContractTypeId)
    .set('ServiceTypeId', ServiceTypeId)
    .set('RequestedAmount', RequestedAmount)
    .set('ReasonForChangeId', ReasonForChangeId)
    .set('ScheduleImpactInDays', ScheduleImpactInDays)
    .set('ScopeOfWork', ScopeOfWork)
    .set('CostEventPriorityId', CostEventPriorityId)
    .set('CriticalSuccessor', CriticalSuccessor)
    .set('CriticalDate', CriticalDate)
    .set('ParentCostEventId', ParentCostEventId)
    .set('BudgetItemId', budgetItemId)
    .set('AssignedAmount', AssignedAmount)
    .set('EditAdditionalTexts', EditAdditionalTexts)
    .set('EditAdditionalDates', EditAdditionalDates)
    .set('editType', EditType)
    .set('CostEventXFundingSources',JSON.stringify(FundingSourceList));
     return this.coreHttpService.httpPutRequest(environment.apiBaseUrl + 'CostEvent' , body);
  //  return null;
  }

  getAssociatedIssuesForCostEvent(id: string) {
    return this.http.get<Response>(environment.apiBaseUrl + API_ENDPOINTS.GET_ASSOCIATED_ISSUES_FOR_COST_EVENT + id);
  }

  getAssociatedTaskForTaskCostEvents(id: string) {
    return this.http.get<Response2>(environment.apiBaseUrl + API_ENDPOINTS.GET_ASSOCIATED_TASK_FOR_TAKS_COST_EVENT + id);
  }

  getPendingAutoCreatedCostEvents() {
    return this.coreHttpService.httpGetWithoutLoaderRequest<any>(
      environment.apiBaseUrl + API_ENDPOINTS.GET_PENDING_AUTO_CREATED_COST_EVENTS);
  }

  getCostEventRequiredInfo(): Observable<any[]> {
    const response1 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + API_ENDPOINTS.SERVICE_TYPE);
    const response2 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + API_ENDPOINTS.CONTRACT_TYPE);
    const response3 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + API_ENDPOINTS.ACTIVE_COSTEVENT_TYPE);
    const response4 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + API_ENDPOINTS.COSTEVENT_REASON);
    const response5 = this.coreHttpService.httpGetRequest(environment.apiBaseUrl + API_ENDPOINTS.VENDOR_LIST);
    
    return observableForkJoin([response1, response2, response3, response4, response5]);
  }

  bulkUpdatePendingCostevents(costeventData: any) {
    const body = new HttpParams()
      .set('CostEventIds', costeventData.costEventId)
      .set('CostEventTypeId', costeventData.costEventType)
      .set('VendorId', costeventData.vendor)
      .set('ContractTypeId', costeventData.contractType)
      .set('ServiceTypeId', costeventData.serviceType)
      .set('ReasonForChangeId', costeventData.reasonForChange);

    return this.coreHttpService.httpPutRequest(environment.apiBaseUrl + API_ENDPOINTS.BULK_UPDATE, body);
  }

  getPendingAutoCreatedCostEventsByProjectId(projectId) {
    return this.coreHttpService.httpGetWithoutLoaderRequest<any>(
      environment.apiBaseUrl + API_ENDPOINTS.GET_PENDING_AUTO_CREATED_COST_EVENTS + '?projectId=' + projectId);
  }
}

